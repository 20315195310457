<template>
  <div class="modals">
    <b-button v-b-modal.modal-updating-item>
      updating item
    </b-button>

    <b-modal
      id="modal-updating-item"
      hide-footer
      hide-header
      modal-class="success-popup"
      ok-only
      no-stacking
    >
      <div class="text-center modal-complete">
        <div class="success-popup-icon success-popup-icon_primary">
          <svg class="icon icon-icon-popup-updating">
            <use xlink:href="#icon-icon-popup-updating" />
          </svg>
        </div>

        <h2>
          {{ $t('applicationupdatepopup.application-update') }}
        </h2>

        <div class="popup-meta">
          <p>
            {{ $t('applicationupdatepopup.new-update-available') }}
          </p>
          <p>
            {{ $t('applicationupdatepopup.update-may-take-2-4-minutes-continue') }}
          </p>
        </div>

        <div class="d-flex justify-content-center align-items-center">
          <b-button
            v-b-modal.modal-updating-item-2
            variant="primary"
            class="mr-2"
          >
            {{ $t('applicationupdatepopup.continue') }}
          </b-button>
          <b-button
            variant="outline-primary"
            @click="$bvModal.hide('modal-updating-item')"
          >
            {{ $t('applicationupdatepopup.cancel') }}
          </b-button>
        </div>
      </div>
    </b-modal>


    <b-button v-b-modal.modal-sync-item>
      {{ $t('syncdatapopup.sync') }}
    </b-button>

    <b-modal
      id="modal-sync-item"
      hide-footer
      hide-header
      modal-class="success-popup"
      ok-only
      no-stacking
    >
      <div class="text-center modal-complete">
        <div class="success-popup-icon success-popup-icon_success">
          <svg class="icon icon-icon-popup-sync">
            <use xlink:href="#icon-icon-popup-sync" />
          </svg>
        </div>

        <h2>
          {{ $t('syncdatapopup.sync-data') }}
        </h2>

        <div class="popup-meta">
          <p>
            {{ $t('syncdatapopup.last-changes-on') }}
          </p>
          <p>
            {{ $t('syncdatapopup.synchronize-new-data') }}
          </p>
        </div>

        <div class="d-flex justify-content-center align-items-center">
          <b-button
            v-b-modal.modal-sync-item-2
            variant="primary"
            class="mr-2"
          >
            {{ $t('syncdatapopup.sync') }}
          </b-button>
          <b-button
            variant="outline-primary"
            @click="$bvModal.hide('modal-sync-item')"
          >
            {{ $t('syncdatapopup.cancel') }}
          </b-button>
        </div>
      </div>
    </b-modal>


    <b-button v-b-modal.modal-sync-item-2>
      Sync2
    </b-button>

    <b-modal
      id="modal-sync-item-2"
      hide-footer
      hide-header
      modal-class="success-popup"
      ok-only
      no-stacking
    >
      <div class="text-center modal-complete">
        <div class="success-popup-icon success-popup-icon_success">
          <svg class="icon icon-icon-popup-sync">
            <use xlink:href="#icon-icon-popup-sync" />
          </svg>
        </div>

        <h2>
          {{ $t('syncprogressdialog.syncing') }}
        </h2>

        <b-progress
          :max="max"
          :striped="false"
          :animated="true"
          height="10px"
          show-progress
        >
          <b-progress-bar
            :value="value"
            variant="success"
          >
            <h5 v-if="value > 0">
              <svg class="icon icon-icon-popup-sync">
                <use xlink:href="#icon-icon-popup-sync" />
              </svg>
            </h5>
          </b-progress-bar>
        </b-progress>

        <div class="d-flex justify-content-center align-items-center">
          <b-button
            variant="outline-success w-100"
            @click="$bvModal.hide('modal-sync-item-2')"
          >
            {{ $t('syncprogressdialog.cancel') }}
          </b-button>
        </div>
      </div>
    </b-modal>


    <b-button v-b-modal.modal-updating-item-2>
      updating2
    </b-button>

    <b-modal
      id="modal-updating-item-2"
      hide-footer
      hide-header
      modal-class="success-popup"
      ok-only
      no-stacking
    >
      <div class="text-center modal-complete">
        <div class="success-popup-icon success-popup-icon_primary">
          <svg class="icon icon-icon-popup-updating">
            <use xlink:href="#icon-icon-popup-updating" />
          </svg>
        </div>

        <h2>
          {{ $t('updateprogressdialog.updating') }}
        </h2>

        <b-progress
          :max="max"
          :striped="false"
          :animated="true"
          height="10px"
          show-progress
        >
          <b-progress-bar
            :value="value"
            variant="primary"
          >
            <h5 v-if="value > 0">
              <svg class="icon icon-icon-popup-updating">
                <use xlink:href="#icon-icon-popup-updating" />
              </svg>
            </h5>
          </b-progress-bar>
        </b-progress>

        <div class="d-flex justify-content-center align-items-center">
          <b-button
            variant="outline-primary w-100"
            @click="$bvModal.hide('modal-updating-item-2')"
          >
            {{ $t('updateprogressdialog.cancel') }}
          </b-button>
        </div>
      </div>
    </b-modal>


    <b-button v-b-modal.modal-1>
      {{ $t('adjustpricepopup.price-adjust') }}
    </b-button>

    <b-modal
      id="modal-1"
      :body-bg-variant="bodyBgVariant"
      :modal-class="popupPrice+' '+'popup-price-btn'"
      no-close-on-backdrop
      hide-footer
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <b-button
          variant="outline-light"
          @click="close()"
        >
          {{ $t('adjustpricepopup.cancel') }}
        </b-button>
        <h5>
          {{ $t('adjustpricepopup.price-adjust') }}
        </h5>
      </template>

      <div class="pin-win">
        <span>$ 50.00 </span>

        <b-button variant="light">
          {{ $t('adjustpricepopup.not-taxed') }}
        </b-button>
      </div>

      <div class="pin-keyboard text-center my-0">
        <ul class="pin-keyboard-list">
          <li>
            <span
              class="pin-key"
              @click="typeCode(1)"
            >1</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(2)"
            >2</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(3)"
            >3</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeCode(4)"
            >4</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(5)"
            >5</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(6)"
            >6</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeCode(7)"
            >7</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(8)"
            >8</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(9)"
            >9</span>
          </li>

          <li><span class="pin-key pin-key_call">C</span></li>

          <li>
            <span
              class="pin-key"
              @click="typePin(0)"
            >0</span>
          </li>

          <li>
            <span
              class="pin-key bg-primary"
              @click="typePin(0)"
            >
              <svg class="icon icon-icon-check">
                <use xlink:href="#icon-icon-check" />
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </b-modal>

    <b-button v-b-modal.modal-11>
      {{ $t('quantitypopup.quantity') }}
    </b-button>

    <b-modal
      id="modal-11"
      :body-bg-variant="bodyBgVariant"
      :modal-class="popupPrice"
      no-close-on-backdrop
      hide-footer
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <b-button
          variant="outline-light"
          @click="close()"
        >
          {{ $t('quantitypopup.cancel') }}
        </b-button>
        <h5>
          {{ $t('quantitypopup.quantity') }}
        </h5>
      </template>

      <div class="pin-win">
        <span>50.00 </span>
      </div>

      <div class="pin-keyboard text-center my-0">
        <ul class="pin-keyboard-list">
          <li>
            <span
              class="pin-key"
              @click="typeCode(1)"
            >1</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(2)"
            >2</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(3)"
            >3</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeCode(4)"
            >4</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(5)"
            >5</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(6)"
            >6</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeCode(7)"
            >7</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(8)"
            >8</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(9)"
            >9</span>
          </li>

          <li><span class="pin-key pin-key_call">C</span></li>
          <li>
            <span
              class="pin-key"
              @click="typePin(0)"
            >0</span>
          </li>
          <li>
            <span
              class="pin-key bg-primary"
              @click="typePin(0)"
            >
              <svg class="icon icon-icon-check">
                <use xlink:href="#icon-icon-check" />
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </b-modal>


    <b-button v-b-modal.modal-2>
      {{ $t('adjustpricepopup.price-adjust') }} Taxed
    </b-button>

    <b-modal
      id="modal-2"
      :body-bg-variant="bodyBgVariant"
      :modal-class="popupPrice+' '+'popup-price-btn'"
      no-close-on-backdrop
      hide-footer
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <b-button
          variant="outline-light"
          @click="close()"
        >
          {{ $t('adjustpricepopup.cancel') }}
        </b-button>
        <h5>
          {{ $t('adjustpricepopup.price-adjust') }}
        </h5>
      </template>

      <div class="pin-win">
        <span>$ 50.00 </span>

        <b-button variant="primary">
          {{ $t('adjustpricepopup.taxed') }}
        </b-button>
      </div>

      <div class="pin-keyboard text-center my-0">
        <ul class="pin-keyboard-list">
          <li>
            <span
              class="pin-key"
              @click="typeCode(1)"
            >1</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(2)"
            >2</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(3)"
            >3</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeCode(4)"
            >4</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(5)"
            >5</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(6)"
            >6</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeCode(7)"
            >7</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(8)"
            >8</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(9)"
            >9</span>
          </li>

          <li><span class="pin-key pin-key_call">C</span></li>

          <li>
            <span
              class="pin-key"
              @click="typePin(0)"
            >0</span>
          </li>

          <li>
            <span
              class="pin-key bg-primary"
              @click="typePin(0)"
            >
              <svg class="icon icon-icon-check">
                <use xlink:href="#icon-icon-check" />
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </b-modal>


    <b-button v-b-modal.modal-3>
      {{ $t('discountpopup.discount') }}
    </b-button>

    <b-modal
      id="modal-3"
      :body-bg-variant="bodyBgVariant"
      :modal-class="popupPrice+' '+popupPriceSidebar+' '+'popup-price-btn'"
      no-close-on-backdrop
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <b-button
          variant="outline-light"
          @click="close()"
        >
          {{ $t('discountpopup.cancel') }}
        </b-button>
        <h5>
          {{ $t('discountpopup.discount') }}
        </h5>
      </template>

      <div class="pin-win">
        <span>50.00 %</span>

        <b-button variant="primary">
          {{ $t('discountpopup.percent-off') }}
        </b-button>
      </div>

      <div class="pin-keyboard text-center my-0">
        <ul class="pin-keyboard-list">
          <li>
            <span
              class="pin-key"
              @click="typeCode(1)"
            >1</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(2)"
            >2</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(3)"
            >3</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeCode(4)"
            >4</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(5)"
            >5</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(6)"
            >6</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeCode(7)"
            >7</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(8)"
            >8</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(9)"
            >9</span>
          </li>

          <li><span class="pin-key pin-key_call">C</span></li>

          <li>
            <span
              class="pin-key"
              @click="typePin(0)"
            >0</span>
          </li>

          <li>
            <span
              class="pin-key bg-primary"
              @click="typePin(0)"
            >
              <svg class="icon icon-icon-check">
                <use xlink:href="#icon-icon-check" />
              </svg>
            </span>
          </li>
        </ul>
      </div>

      <div
        slot="modal-footer"
        class="w-100"
      >
        <h5>
          {{ $t('discountpopup.discounts') }}
        </h5>

        <ul class="list-unstyled ">
          <li>
            <a href="#">
              <span>
                Employee Discount
              </span>
            </a>
          </li>
          <li>
            <a
              href="#"
              class="active"
            >
              <span>
                Seyda’s Discount
              </span>
            </a>
          </li>
          <li>
            <a href="#">
              <span>
                Student Discount
              </span>
            </a>
          </li>
          <li>
            <a href="#">
              <span>
                Employee Discount
              </span>
            </a>
          </li>
          <li>
            <a href="#">
              <span>
                Student Discount
              </span>
            </a>
          </li>


          <li>
            <a href="#">
              <span>
                Employee Discount
              </span>
            </a>
          </li>
          <li>
            <a href="#">
              <span>
                Student Discount
              </span>
            </a>
          </li>
        </ul>
      </div>
    </b-modal>


    <b-button v-b-modal.modal-4>
      Discount Off
    </b-button>

    <b-modal
      id="modal-4"
      :body-bg-variant="bodyBgVariant"
      :modal-class="popupPrice+' '+popupPriceSidebar+' '+'popup-price-btn'"
      no-close-on-backdrop
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <b-button
          variant="outline-light"
          @click="close()"
        >
          {{ $t('discountpopup.cancel') }}
        </b-button>
        <h5>
          {{ $t('discountpopup.discount') }}
        </h5>
      </template>

      <div class="pin-win">
        <span>50.00 %</span>

        <b-button variant="light">
          {{ $t('discountpopup.percent-off') }}
        </b-button>
      </div>

      <div class="pin-keyboard text-center my-0">
        <ul class="pin-keyboard-list">
          <li>
            <span
              class="pin-key"
              @click="typeCode(1)"
            >1</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(2)"
            >2</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(3)"
            >3</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeCode(4)"
            >4</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(5)"
            >5</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(6)"
            >6</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeCode(7)"
            >7</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(8)"
            >8</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(9)"
            >9</span>
          </li>

          <li><span class="pin-key pin-key_call">C</span></li>

          <li>
            <span
              class="pin-key"
              @click="typePin(0)"
            >0</span>
          </li>

          <li>
            <span
              class="pin-key bg-primary"
              @click="typePin(0)"
            >
              <svg class="icon icon-icon-check">
                <use xlink:href="#icon-icon-check" />
              </svg>
            </span>
          </li>
        </ul>
      </div>

      <div
        slot="modal-footer"
        class="w-100"
      >
        <h5>
          {{ $t('discountpopup.discounts') }}
        </h5>

        <ul class="list-unstyled ">
          <li>
            <a href="#">
              <span>
                Employee Discount
              </span>
            </a>
          </li>
          <li>
            <a
              href="#"
              class="active"
            >
              <span>
                Seyda’s Discount
              </span>
            </a>
          </li>
          <li>
            <a href="#">
              <span>
                Student Discount
              </span>
            </a>
          </li>
          <li>
            <a href="#">
              <span>
                Employee Discount
              </span>
            </a>
          </li>
          <li>
            <a href="#">
              <span>
                Student Discount
              </span>
            </a>
          </li>


          <li>
            <a href="#">
              <span>
                Employee Discount
              </span>
            </a>
          </li>
          <li>
            <a href="#">
              <span>
                Student Discount
              </span>
            </a>
          </li>
        </ul>
      </div>
    </b-modal>


    <br>

    <b-button @click="$bvModal.show('modal-cansel-order')">
      Cansel Order
    </b-button>

    <b-modal
      id="modal-cansel-order"
      hide-footer
      hide-header
      modal-class="success-popup"
      no-fade
    >
      <div class="text-center modal-complete">
        <svg class="icon icon-icon-popup-cansel">
          <use xlink:href="#icon-icon-popup-cansel" />
        </svg>

        <h2>
          {{ $t('cancelorderpopup.cancel-this-order') }}
        </h2>

        <span class="popup-meta">
          {{ $t('cancelorderpopup.are-you-sure-you-want-to-cancel-this-order') }}
        </span>

        <div class="d-flex justify-content-center align-items-center">
          <b-button
            variant="outline-primary"
            @click="$bvModal.hide('modal-cansel-order')"
          >
            {{ $t('cancelorderpopup.no') }}
          </b-button>
          <b-button
            variant="primary"
            class="ml-2"
          >
            {{ $t('cancelorderpopup.yes') }}
          </b-button>
        </div>
      </div>
    </b-modal>


    <b-button @click="$bvModal.show('modal-remove-item')">
      Remove item
    </b-button>

    <b-modal
      id="modal-remove-item"
      hide-footer
      hide-header
      modal-class="success-popup"
    >
      <div class="text-center modal-complete">
        <svg class="icon icon-icon-popup-remove">
          <use xlink:href="#icon-icon-popup-remove" />
        </svg>

        <h2>
          {{ $t('removeitempopup.remove-this-item') }}
        </h2>

        <span class="popup-meta">
          {{ $t('removeitempopup.are-you-sure-you-want-to-remove-this-item') }}
        </span>

        <div class="d-flex justify-content-center align-items-center">
          <b-button
            variant="outline-danger"
            @click="$bvModal.hide('modal-remove-item')"
          >
            {{ $t('removeitempopup.no') }}
          </b-button>
          <b-button
            variant="danger"
            class="ml-2"
          >
            {{ $t('removeitempopup.yes') }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-button @click="$bvModal.show('modal-hold-order')">
      Hold order
    </b-button>

    <b-modal
      id="modal-hold-order"
      hide-footer
      hide-header
      modal-class="success-popup"
    >
      <div class="text-center modal-complete">
        <svg class="icon icon-icon-popup-hold">
          <use xlink:href="#icon-icon-popup-hold" />
        </svg>

        <h2>
          {{ $t('orderscreen.hold-this-order') }}
        </h2>

        <textarea
          :placeholder="$t('orderscreen.note-this-placeholder')"
          class="popup-control mb-4"
        />

        <div class="d-flex justify-content-center align-items-center">
          <b-button
            variant="outline-warning"
            @click="$bvModal.hide('modal-hold-order')"
          >
            {{ $t('orderscreen.no-c2f3f489a00553e7a01d369c103c7251') }}
          </b-button>
          <b-button
            variant="warning"
            class="ml-2"
          >
            {{ $t('orderscreen.yes-c2f3f489a00553e7a01d369c103c7251') }}
          </b-button>
        </div>
      </div>
    </b-modal>


    <b-button @click="$bvModal.show('modal-note-order')">
      Note order
    </b-button>

    <b-modal
      id="modal-note-order"
      hide-footer
      hide-header
      modal-class="success-popup"
    >
      <div class="text-center modal-complete">
        <svg class="icon icon-icon-popup-note">
          <use xlink:href="#icon-icon-popup-note" />
        </svg>

        <h2>
          {{ $t('orderscreen.note-this-order') }}
        </h2>

        <textarea
          :placeholder="$t('orderscreen.note-this-placeholder')"
          class="popup-control mb-4"
        />

        <div class="d-flex justify-content-center align-items-center">
          <b-button
            variant="outline-warning"
            @click="$bvModal.hide('modal-note-order')"
          >
            {{ $t('orderscreen.cancel') }}
          </b-button>
          <b-button
            variant="warning"
            class="ml-2"
          >
            {{ $t('orderscreen.done') }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-button @click="$bvModal.show('modal-wrong')">
      Wrong
    </b-button>

    <b-modal
      id="modal-wrong"
      hide-footer
      hide-header
      modal-class="success-popup success-popup_wrong"
    >
      <div class="text-center modal-complete">
        <svg class="icon icon-icon-popup-wrong">
          <use xlink:href="#icon-icon-popup-wrong" />
        </svg>

        <h2>
          {{ $t('errorpopup.something-went-wrong') }}
        </h2>

        <div class="popup-meta">
          {{ $t('errorpopup.could-not-sync-the-system') }}
        </div>

        <div class="d-flex justify-content-center align-items-center">
          <b-button
            variant="outline-warning"
            class="w-100"
            @click="$bvModal.hide('modal-wrong')"
          >
            {{ $t('errorpopup.close') }}
          </b-button>
        </div>
      </div>
    </b-modal>


    <b-button v-b-modal.modal-open-shift>
      {{ $t('shiftviewpopup.open-shift') }}
    </b-button>

    <b-modal
      id="modal-open-shift"
      :body-bg-variant="bodyBgVariant"
      :modal-class="popupPrice+' '+popupPriceSidebar"
      no-close-on-backdrop
      hide-footer
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <b-button
          variant="outline-light"
          @click="close()"
        >
          {{ $t('shiftviewpopup.cancel') }}
        </b-button>
        <h5>
          {{ $t('shiftviewpopup.open-shift') }}
        </h5>
        <b-button variant="primary">
          {{ $t('shiftviewpopup.open-shift') }}
        </b-button>
      </template>

      <div class="modal-top text-center">
        <p>
          {{ $t('shiftviewpopup.a-shift-has-not-yet-been-opened') }}
        </p>
        <p>
          {{ $t('shiftviewpopup.enter-starting-cash-amount') }}
        </p>
      </div>

      <div class="pin-win">
        <span>$525.00</span>
      </div>

      <div class="pin-keyboard text-center my-0">
        <ul class="pin-keyboard-list">
          <li>
            <span
              class="pin-key"
              @click="typeCode(1)"
            >1</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(2)"
            >2</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(3)"
            >3</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeCode(4)"
            >4</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(5)"
            >5</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(6)"
            >6</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeCode(7)"
            >7</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(8)"
            >8</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(9)"
            >9</span>
          </li>

          <li><span class="pin-key pin-key_call">C</span></li>

          <li>
            <span
              class="pin-key"
              @click="typePin(0)"
            >0</span>
          </li>

          <li>
            <span
              class="pin-key pin-key_call"
              @click="typePin(0)"
            >
              <svg class="icon icon-icon-pin-arrow">
                <use xlink:href="#icon-icon-pin-arrow" />
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </b-modal>


    <b-button v-b-modal.modal-close-shift>
      Close Shift
    </b-button>

    <b-modal
      id="modal-close-shift"
      :body-bg-variant="bodyBgVariant"
      :modal-class="popupPrice+' '+popupPriceSidebar"
      no-close-on-backdrop
      hide-footer
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <b-button
          variant="outline-light"
          @click="close()"
        >
          {{ $t('shiftviewclosingcurrentshiftpopup.cancel') }}
        </b-button>
        <h5>
          {{ $t('shiftviewclosingcurrentshiftpopup.count-your-drawer') }}
        </h5>
        <b-button variant="danger">
          {{ $t('shiftviewclosingcurrentshiftpopup.close-shift') }}
        </b-button>
      </template>

      <div class="modal-top text-center">
        <p>
          {{ $t('shiftviewclosingcurrentshiftpopup.you-are-about-to-close-shift') }}
        </p>
        <p>
          {{ $t('shiftviewclosingcurrentshiftpopup.enter-end-cash-amount') }}
        </p>
      </div>

      <div class="pin-win">
        <span>$2107.47</span>
      </div>

      <div class="pin-keyboard text-center my-0">
        <ul class="pin-keyboard-list">
          <li>
            <span
              class="pin-key"
              @click="typeCode(1)"
            >1</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(2)"
            >2</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(3)"
            >3</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeCode(4)"
            >4</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(5)"
            >5</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(6)"
            >6</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeCode(7)"
            >7</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(8)"
            >8</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeCode(9)"
            >9</span>
          </li>

          <li><span class="pin-key pin-key_call">C</span></li>

          <li>
            <span
              class="pin-key"
              @click="typePin(0)"
            >0</span>
          </li>

          <li>
            <span
              class="pin-key pin-key_call"
              @click="typePin(0)"
            >
              <svg class="icon icon-icon-pin-arrow">
                <use xlink:href="#icon-icon-pin-arrow" />
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </b-modal>


    <b-button v-b-modal.modal-status-success-shift>
      Shift Summary Success
    </b-button>

    <b-modal
      id="modal-status-success-shift"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="light"
      body-bg-variant="white"
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <b-button
          variant="outline-light"
          @click="close()"
        >
          {{ $t('shiftviewdetailspopup.cancel') }}
        </b-button>
        <h5>
          {{ $t('shiftviewdetailspopup.shift-summary') }}
        </h5>
        <b-button variant="danger">
          {{ $t('shiftviewcurrentshiftpopup.close-shift') }}
        </b-button>
      </template>

      <div class="shift">
        <div class="shift-top">
          <div class="shift-top-icon">
            <svg class="icon icon-icon-shift">
              <use xlink:href="#icon-icon-shift" />
            </svg>
          </div>
          <div class="shift-top-body">
            <div class="d-flex align-items-center">
              <div class="shift-title mr-3">
                {{ $t('shiftviewcurrentshiftpopup.shift-0') }}
              </div>
              <div class="shift-status bg-success">
                {{ $t('shiftviewcurrentshiftpopup.active') }}
              </div>
            </div>
            <div class="shift-meta">
              {{ $t('shiftviewdetailspopup.opened') }} Today at 12:01 AM by NESE
            </div>
          </div>
        </div>

        <div class="d-flex shift-total-row">
          <div class="shift-total">
            <div class="shift-total-inner">
              <strong>52</strong>
              <span>
                {{ $t('shiftviewdetailspopup.total-transactions') }}
              </span>
            </div>
          </div>
          <div class="shift-total">
            <div class="shift-total-inner">
              <strong>$ 2107.47</strong>
              <span>
                {{ $t('shiftviewdetailspopup.total-net-sales') }}
              </span>
            </div>
          </div>
        </div>

        <div class="shift-list">
          <table>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.opening-amount') }}
              </td>
              <td>
                $525.00
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.cash-sales') }}
              </td>
              <td>
                $2107.47
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.cash-returns') }}
              </td>
              <td>
                0.00
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.check-sales') }}
              </td>
              <td>
                0.00
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.check-returns') }}
              </td>
              <td>
                $520.00
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.drops') }}
              </td>
              <td>
                0.00
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.payouts') }}
              </td>
              <td>
                $300.00
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.closing-amount') }}
              </td>
              <td>
                -
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.over-short') }}
              </td>
              <td>
                -
              </td>
            </tr>
          </table>
        </div>

        <div class="text-center">
          <b-button
            variant="outline-light"
            size="lg"
          >
            {{ $t('shiftviewcurrentshiftpopup.print-x-report') }}
          </b-button>
        </div>
      </div>
    </b-modal>


    <b-button v-b-modal.modal-status-danger-shift>
      Shift Summary Danger
    </b-button>

    <b-modal
      id="modal-status-danger-shift"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="light"
      body-bg-variant="white"
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <b-button
          variant="outline-light"
          @click="close()"
        >
          {{ $t('shiftviewdetailspopup.cancel') }}
        </b-button>
        <h5>
          {{ $t('shiftviewdetailspopup.shift-summary') }}
        </h5>
        <b-button variant="danger">
          {{ $t('shiftviewcurrentshiftpopup.close-shift') }}
        </b-button>
      </template>

      <div class="shift">
        <div class="shift-top">
          <div class="shift-top-icon">
            <svg class="icon icon-icon-shift">
              <use xlink:href="#icon-icon-shift" />
            </svg>
          </div>
          <div class="shift-top-body">
            <div class="d-flex align-items-center">
              <div class="shift-title mr-3">
                {{ $t('shiftviewdetailspopup.shift-0') }}
              </div>
              <div class="shift-status bg-danger">
                {{ $t('shiftviewdetailspopup.active') }}
              </div>
            </div>
            <div class="shift-meta">
              {{ $t('shiftviewdetailspopup.opened') }} Today at 12:01 AM by NESE
            </div>
          </div>
        </div>

        <div class="d-flex shift-total-row">
          <div class="shift-total">
            <div class="shift-total-inner">
              <strong>52</strong>
              <span>
                {{ $t('shiftviewdetailspopup.total-transactions') }}
              </span>
            </div>
          </div>
          <div class="shift-total">
            <div class="shift-total-inner">
              <strong>$ 2107.47</strong>
              <span>
                {{ $t('shiftviewdetailspopup.total-net-sales') }}
              </span>
            </div>
          </div>
        </div>

        <div class="shift-list">
          <table>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.opening-amount') }}
              </td>
              <td>
                $525.00
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.cash-sales') }}
              </td>
              <td>
                $2107.47
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.cash-returns') }}
              </td>
              <td>
                0.00
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.check-sales') }}
              </td>
              <td>
                0.00
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.check-returns') }}
              </td>
              <td>
                $520.00
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.drops') }}
              </td>
              <td>
                0.00
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.payouts') }}
              </td>
              <td>
                $300.00
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.closing-amount') }}
              </td>
              <td>
                -
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('shiftviewdetailspopup.over-short') }}
              </td>
              <td>
                -
              </td>
            </tr>
          </table>
        </div>

        <div class="text-center">
          <b-button
            variant="outline-light"
            size="lg"
          >
            {{ $t('shiftviewcurrentshiftpopup.print-x-report') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: 'Modals',
    data () {
      return {
        timer: 0,
        value: 0,
        max: 180,
        modalShow: false,
        bodyBgVariant: 'light',
        popupPrice: ['popup-price'],
        popupPriceSidebar: ['popup-price-sidebar']
      };
    },
    mounted () {
      this.startTimer();
    },
    methods: {
      startTimer () {
        const vm = this;
        const timer = setInterval(function () {
          vm.value += 2;
          if (vm.value >= 180) clearInterval(timer);
        }, 50);
      }
    }
  };
</script>
